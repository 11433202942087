import type { File } from '@motif/types'

import { PREVIEW_SUBDOMAIN_EXTENSION } from './constants'
import { isProd } from './env'

const MOTIF_LOCAL_DOMAIN = 'localhost'
const MOTIF_LAND_DOMAIN = 'motif.land'

export type DomainInfo = { isProjectDomain: boolean; domainSlug: string }

// The `live` project has a fixed URL (in addition to all the
// dynamically registered domains). This URL is used for instance
// to invalidate the FS cache for all projects.
export const MOTIF_LIVE_PROJECT_VERCEL_DOMAIN_PRODUCTION =
  'https://motif-live.vercel.app'
export const MOTIF_LIVE_PROJECT_VERCEL_DOMAIN_PREVIEW =
  'https://motif-preview.vercel.app'

export const getBaseHost = (includePort = false, live = false): string => {
  // motif.land, motif.dev, localhost or localhost:3000|3001 (if
  // includePort=true and live=false|true)
  let baseHost
  if (isProd()) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview') {
      if (global.window) {
        // In preview mode, motif.dev or motif.fun are in use.
        baseHost = window?.location?.host
      } else {
        // Fallback to motif.dev
        baseHost = 'motif.dev'
      }
    } else {
      baseHost = MOTIF_LAND_DOMAIN
    }
  } else {
    baseHost = MOTIF_LOCAL_DOMAIN
  }
  if (!includePort) {
    return baseHost
  } else {
    const port = getPort(live)
    return baseHost + (port ? ':' + port : '')
  }
}

export function getDomainWithoutSubdomain(url: string) {
  const parsedUrl = new URL(url)
  const hostname = parsedUrl.hostname

  const domainParts = hostname.split('.')
  const isLocalhost = domainParts.slice(-1)[0] === 'localhost'

  if (isLocalhost) {
    // Remove the subdomain if it exists
    const domain = domainParts.slice(-1).join('.')
    return `${getScheme()}${domain}:${getPort(false)}`
  }

  const domain = domainParts.slice(-2).join('.')
  return `${getScheme()}${domain}:${getPort(false)}`
}

export const isMotifSubdomainHost = (host: string) => {
  return host.endsWith(getBaseHost(true, true))
}

const getScheme = (): string => {
  if (isProd()) {
    return 'https://'
  } else {
    return 'http://'
  }
}

const getPort = (live: boolean): number | null => {
  if (isProd()) {
    return null
  } else {
    if (live) {
      return 3001
    } else {
      return 3000
    }
  }
}

export const getOrigin = (
  subdomain: string | null = null,
  live = false,
  queryParams?: string
): string => {
  // If no subdomain is provided, return:
  // https://motif.land,
  // https://motif.dev,
  // http://motif.local:3000 (live=false) or
  // http://motif.local:3001 (live=true)
  //
  // If subdomain is provided, return:
  // https://acme.motif.land,
  // https://acme.motif.dev,
  // http://acme.motif.local:3000 (live=false) or
  // http://acme.motif.local:3001 (live=true)
  return (
    getScheme() +
    (subdomain ? subdomain + '.' : '') +
    getBaseHost(true, live) +
    (queryParams ? `?${queryParams}` : '')
  )
}

export const getCurrentOrigin = (): string => {
  // https://acme.motif.land, https://acme.motif.dev or http://acme.motif.local:3000
  return getOrigin(getCurrentSubdomain(), false)
}

export const getCurrentSubdomain = (): string | null => {
  // Returns the current subdomain if the user requests the page
  // from Motif, i.e. acme.motif.land -> acme. Otherwise, return null.
  if (typeof window === 'undefined') {
    return null
  }
  const domain = `.${getBaseHost()}`
  const host = window?.location?.host
  if (host.includes(domain)) {
    return host.split(domain)?.[0]
  }
  return null
}

export const getCDNOrigin = () => {
  return getOrigin('cdn')
}

export const getPublicProfileUrl = (handle: string) => {
  return `${getOrigin()}/@${handle}`
}

export const getMotifModuleImportUrl = (
  moduleName: string,
  version?: string
) => {
  return `${getCDNOrigin()}/${moduleName}${version ? `@${version}` : ''}`
}

export const getFileAppAbsolutePath = (id: File['id']): string => {
  return `/files/${id}`
}

export const getFileAppUrl = (id: File['id']): string => {
  return `${getOrigin()}${getFileAppAbsolutePath(id)}`
}

export const getPreviewBaseUrl = (projectDomain: string) => {
  return `${getOrigin(`${projectDomain}${PREVIEW_SUBDOMAIN_EXTENSION}`, true)}`
}

export const getPreviewUrl = (projectDomain: string, path: string) => {
  return `${getPreviewBaseUrl(projectDomain)}${path}`
}

export const getCustomDomainUrl = (customDomain: string) => {
  return `${getScheme()}${customDomain}`
}

export const getDomainInfo = (hostname: string): DomainInfo => {
  const motifBaseHost = getBaseHost(true, true)
  if (hostname.endsWith('.' + motifBaseHost)) {
    const subdomain = hostname.replace('.' + motifBaseHost, '')
    return { isProjectDomain: true, domainSlug: subdomain }
  } else {
    const hostnameWithoutPort = hostname.split(':')[0]
    return { isProjectDomain: false, domainSlug: hostnameWithoutPort }
  }
}
