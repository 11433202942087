// This file may be imported within the Vercel edge runtime, so
// make sure it doesn't contain any Node.js functions, such as
// Buffer (which is why we import Buffer here as a library).
import { Buffer } from 'buffer'

import { getCurrentOrigin } from '@motif/shared/domain.edge'
import type { ApiToken, User } from '@motif/types'

export const timeout = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const copyToClipboard = (text: string): void => {
  navigator?.clipboard && navigator.clipboard.writeText(text)
}

export const generateRandomAlphanumericString = (length: number): string => {
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = length; i > 0; --i) {
    result += mask[Math.floor(Math.random() * mask.length)]
  }
  return result
}

export const isNull = (item: any): boolean => {
  return item === null || typeof item === 'undefined'
}

export const isNotNull = (item: any): boolean => {
  return !isNull(item)
}

export const isEmptyDict = (d: any): boolean => {
  return d === null || typeof d === 'undefined' || Object.keys(d).length === 0
}

export const isDefined = (value: any): boolean => {
  return value !== null && typeof value !== 'undefined'
}

export const isNumber = (text: any): boolean => {
  return !isNaN(text) && !isNaN(parseFloat(text))
}

export const getPageTitle = (title: string | null): string => {
  return title || 'Untitled'
}

export const getPagePath = (path: string | null): string => {
  if (typeof path === 'string') {
    return path || '/'
  }
  return ''
}

export const getQueryParameter = (url: string, name: string): string | null => {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const lowercaseTrimmed = (text: string): string => {
  return text.toLocaleLowerCase().trim()
}

export const toValidSinglePathComponent = (name: string): string => {
  // Ensure name consists of only alphanumeric characters and dashes, and
  // does not begin or end with dashes.
  return name
    .toLowerCase()
    .replace(/\s+/gi, '-')
    .replace(/[^0-9a-z-]/gi, '')
    .replace(/^-+/gi, '')
    .replace(/-+$/gi, '')
}

export const normalizeDomain = (domain: string): string => {
  // Ensure domain consists of only alphanumeric characters and dashes, and
  // does not begin or end with dashes.
  return domain
    .toLowerCase()
    .replace(/[^0-9a-z-]/gi, '')
    .replace(/^-+/gi, '')
    .replace(/-+$/gi, '')
}

export const normalizeModuleName = (moduleName: string): string => {
  // Ensure module name consists of only alphanumeric characters and
  // dashes, and does not begin or end with dashes.
  return moduleName
    .toLowerCase()
    .replace(/[^0-9a-z-]/gi, '')
    .replace(/^-+/gi, '')
    .replace(/-+$/gi, '')
}

export const normalizeHandle = (handle: string): string => {
  // Ensure handle consists of only alphanumeric characters and
  // underscores.
  return handle.toLowerCase().replace(/[^0-9a-z_]/gi, '')
}

export const normalizeEmail = (email: string): string => {
  return email.toLowerCase().trim()
}

export const normalizePath = (path: string): string => {
  // - To lowercase
  // - Remove all non-(alphanumeric+dash+slash) characters
  // - Remove trailing dashes and slashes
  return path
    .toLowerCase()
    .replace(/[^0-9a-z\-/]/gi, '')
    .replace(/^-+/gi, '') // Remove trailing dashes
    .replace(/-+$/gi, '') // Remove leading dashes
    .replace(/^\/+/gi, '') // Remove trailing slashes
    .replace(/\/+$/gi, '') // Remove leading slashes
}

export const normalizeJSVariableName = (name: string): string => {
  // Like normalizeNodeName, but replacing dashes, spaces and periods
  // with underscores, and with no removal of initial underscores.
  //
  // "_-My Blog Post-1" → "__my_blog_post_1"
  //
  // -> This is the function that is used for converting
  //    file paths to export maps.
  return name
    .toLowerCase()
    .trim()
    .replace(/[^0-9a-z\-\s._]/gi, '')
    .replace(/[-.\s]/gi, '_') // Replace dashes, spaces, periods with underscores
}

export const normalizeSpaceOrProjectName = (name: string): string => {
  // - To lowercase
  // - Trim
  return name.toLowerCase().trim()
}

export const sanitizeNodeName = (name: string | null): string | null => {
  // - Remove all non-(alphanumeric+dash+space+period) characters
  // - Remove leading/trailing dashes/spaces
  if (!name) {
    return null
  }
  return name
    .trim()
    .replace(/[^0-9a-z\-\s.]/gi, '')
    .replace(/^-+/gi, '') // Remove dashes at start
    .replace(/-+$/gi, '') // Remove dashes at end
    .replace(/\.html$/gi, '') // Remove .html at end
}

export const isRelativeLink = (href: string | null): boolean => {
  if (typeof href !== 'string') {
    return false
  }
  const re = new RegExp('^https?:\\/\\/', 'i')
  return !re.test(href)
}

export const isUrl = (href: string | null): boolean => {
  if (typeof href !== 'string') {
    return false
  }
  const re = new RegExp('^https?:\\/\\/', 'i')
  return re.test(href)
}

export const isInternalLink = (href: string | null): boolean => {
  if (typeof href !== 'string') {
    return false
  }
  const re = new RegExp('^https?:\\/\\/[^.]*.?motif.land', 'i')
  return re.test(href)
}

export const generateRandomString = (length: number): string => {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const generatePageId = (): string => {
  return generateRandomString(16)
}

export const getUserColorNumber = (id: User['id']): number => {
  try {
    return parseInt(id) % 8
  } catch {
    return 0
  }
}

export const getPageLink = (pathOrId: string): string => {
  return `${getCurrentOrigin()}/${pathOrId}`
}

export const countWords = (text: string): number => {
  return (
    text?.split(' ').filter((word) => {
      return word != ''
    }).length || 0
  )
}

export const removeTrailingSlash = (text: string): string => {
  const trimmed = text.trim()
  if (trimmed.startsWith('/')) {
    return trimmed.substring(1)
  }
  return trimmed
}

export const removeTailSlash = (text: string): string => {
  const trimmed = text.trim()
  return trimmed.replace(/\/$/, '')
}

export const removeTailNewline = (text: string): string => {
  return text.replace(/\n$/g, '')
}

export const removeBoundarySlashes = (text: string): string => {
  return removeTailSlash(removeTrailingSlash(text))
}

export const removeSchema = (url: string): string => {
  return url.replace(/(^\w+:|^)\/\//, '')
}

export const parseIntOrNull = (text: any | null): number | null => {
  if (!text || typeof text !== 'string' || text.length === 0) {
    return null
  }
  const value = parseInt(text)
  if (isNaN(value)) {
    return null
  }
  return value
}

export const isLightColor = (rgba: number[]): boolean => {
  return rgbaToHSL(rgba) > 127.5
}

const trimAlphaChannel = (colorString: string) => {
  return colorString.replace(/^\s+|\s+$/gm, '')
}

export const parseRGBAString = (rgbaString: string): number[] => {
  const inParts = rgbaString.substring(rgbaString.indexOf('(')).split(',')
  const r = parseInt(trimAlphaChannel(inParts[0].substring(1)), 10)
  const g = parseInt(trimAlphaChannel(inParts[1]), 10)
  const b = parseInt(trimAlphaChannel(inParts[2]), 10)
  let a = 1
  try {
    a = parseFloat(
      trimAlphaChannel(inParts[3].substring(0, inParts[3].length - 1))
    ) //.toFixed(2)
  } catch {
    // Do nothing
  }
  return [r, g, b, a]
}

export const getAlphaFromRGBA = (rgba: number[]): number => {
  try {
    return rgba[3]
  } catch {
    return 1
  }
}

export const rgbaToHex = (rgba: number[]): string => {
  const [r, g, b, a] = rgba
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ]

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part
    }
  })

  return '#' + outParts.join('')
}

export const rgbaToHSL = (rgba: number[]): number => {
  const [r, g, b] = rgba
  return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
}

export const areArraysEqualSets = (a1: any[], a2: any[]) => {
  try {
    const superSet: any = {}
    for (const i of a1) {
      const e = i + typeof i
      superSet[e] = 1
    }

    for (const i of a2) {
      const e = i + typeof i
      if (!superSet[e]) {
        return false
      }
      superSet[e] = 2
    }

    for (const e in superSet) {
      if (superSet[e] === 1) {
        return false
      }
    }

    return true
  } catch {
    return false
  }
}

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

// Given a candidate, e.g. "some text", return the candidate if
// it is not in the list of taken names. Otherwise, append a number
// to the candidate until it is no longer in the list of taken
// names, e.g. "some text 2" if takenNames contains "some text"
// and "some text 1"
export const addNumberUntilAvailable = (
  candidate: string,
  takenNames: string[]
) => {
  let currentCandidate = candidate
  let c = 1
  while (takenNames.includes(currentCandidate)) {
    currentCandidate = `${candidate} ${c}`
    c = c + 1
  }
  return currentCandidate
}

export const clearSelection = (): void => {
  if (typeof window === 'undefined') {
    return
  }
  try {
    if (window && window.getSelection) {
      if (window.getSelection()?.empty) {
        // Chrome
        window.getSelection()?.empty()
      } else if (window.getSelection()?.removeAllRanges) {
        // Firefox
        window.getSelection()?.removeAllRanges()
      }
    }
  } catch {
    // Do nothing
  }
}

export const isElementInViewport = (el: any): boolean => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  )
}

export const hideMiddle = (
  text: string,
  offsetStart = 2,
  offsetEnd = 4
): string => {
  if (text.length <= offsetStart + offsetEnd) {
    return text
  }
  return text.substring(0, offsetStart) + '...' + text.slice(-offsetEnd)
}

export const obfuscateTokens = (tokens: ApiToken[]): ApiToken[] => {
  return tokens.map((t: ApiToken) => {
    return {
      ...t,
      value: hideMiddle(t.value, 2, 4),
    }
  })
}

export const extractLineColumnFromMessage = (
  message: string
): {
  startLine: number | undefined
  startColumn: number | undefined
  endLine: number | undefined
  endColumn: number | undefined
} => {
  // Extra line and column values from a message of the form
  // "some text 3:1-3:7 and some more", as served e.g. by the MDX
  // parser.
  try {
    const linePart = message.match(/\d+:\d+-\d+:\d+/g)?.[0]
    if (linePart) {
      const startEnd = linePart.split('-')
      const startLineColumn = startEnd[0].split(':')
      const endLineColumn = startEnd[1].split(':')
      return {
        startLine: parseInt(startLineColumn[0]),
        startColumn: parseInt(startLineColumn[1]),
        endLine: parseInt(endLineColumn[0]),
        endColumn: parseInt(endLineColumn[1]),
      }
    }
  } catch {
    // Do nothing
  }
  return {
    startLine: undefined,
    startColumn: undefined,
    endLine: undefined,
    endColumn: undefined,
  }
}

export const getPointerPosition = (
  event: any
): { x: number; y: number } | null => {
  if (event.targetTouches) {
    if (event.targetTouches.length === 1) {
      return {
        x: event.targetTouches[0].clientX,
        y: event.targetTouches[0].clientY,
      }
    }
    return null
  }
  return { x: event.clientX, y: event.clientY }
}

export const byteSize = (payload: string): number => {
  return Buffer.byteLength(payload)
}

export const getDuplicates = (array: any[]) => {
  const sorted = array.slice().sort()
  const results = []
  for (let i = 0; i < sorted.length - 1; i++) {
    if (sorted[i + 1] == sorted[i]) {
      results.push(sorted[i])
    }
  }
  return results
}

export const splitInChunks = (arr: any[], numItemsPerChunk: number) => {
  const result = []
  for (let i = 0; i < arr.length; i += numItemsPerChunk) {
    result.push(arr.slice(i, i + numItemsPerChunk))
  }
  return result
}
