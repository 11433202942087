import cn from 'classnames'
import type { ChangeEvent, ComponentPropsWithoutRef, ReactNode } from 'react'
import { forwardRef } from 'react'

import s from './Input.module.css'

interface InputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  className?: string
  inputClassName?: string
  dimension?: 'small' | 'normal' | 'large'
  rightAccessoryText?: ReactNode
  rightAccessory?: ReactNode
  leftAccessoryText?: string
  includeAccessoryBorder?: boolean
  darkAccessoryText?: boolean
  plain?: boolean
  autoFocus?: boolean
  noRing?: boolean
  onChange?: (value: string) => void
}

const Input = forwardRef<HTMLInputElement | undefined, InputProps>(
  (
    {
      className,
      inputClassName,
      dimension = 'normal',
      plain,
      rightAccessoryText,
      rightAccessory,
      leftAccessoryText,
      includeAccessoryBorder,
      darkAccessoryText,
      autoFocus,
      noRing,
      onChange,
      ...rest
    }: any,
    ref
  ) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value)
      }
      return null
    }

    return (
      <div
        className={cn(
          className,
          'group flex w-full flex-row items-center overflow-hidden rounded-md text-sm placeholder-neutral-400 transition duration-200 ease-in-out focus-within:bg-white focus-within:outline-none',
          {
            'py-[4px] px-2': dimension === 'small' && !includeAccessoryBorder,
            'py-[7px] px-2': dimension === 'normal' && !includeAccessoryBorder,
            'py-3 px-2': dimension === 'large' && !includeAccessoryBorder,
            [s.withBackground]: !plain,
            [s.withPlainBackground]: plain,
            [s.withBorder]: !plain,
            [s.withRingSmall]: dimension === 'small' && !plain && !noRing,
            [s.withRing]: dimension !== 'small' && !plain && !noRing,
            'cursor-not-allowed': rest.disabled,
          }
        )}
      >
        {leftAccessoryText && (
          <p
            className={cn(
              'mr-2 h-full flex-none select-none whitespace-nowrap text-sm antialiased',
              {
                'text-neutral-400': !darkAccessoryText,
                'text-neutral-900': darkAccessoryText,
                'h-full border-r border-neutral-200 px-2':
                  includeAccessoryBorder,
                'py-[4px] px-2':
                  dimension === 'small' && includeAccessoryBorder,
                'py-[7px] px-2':
                  dimension === 'normal' && includeAccessoryBorder,
                'py-3 px-2': dimension === 'large' && includeAccessoryBorder,
              }
            )}
          >
            {leftAccessoryText}
          </p>
        )}
        <div className="flex-grow">
          <input
            ref={ref}
            className={cn(
              inputClassName,
              'group w-full flex-none outline-none transition duration-200 ease-in-out focus:outline-none disabled:cursor-not-allowed',
              {
                [s.withBackground]: !plain,
                [s.withPlainBackground]: plain,
              }
            )}
            onChange={handleOnChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoFocus={autoFocus}
            {...rest}
          />
        </div>
        {rightAccessoryText && (
          <p className="mx-2 flex-none select-none text-sm text-neutral-400">
            {rightAccessoryText}
          </p>
        )}
        {rightAccessory}
      </div>
    )
  }
)

Input.displayName = 'Input'

export default Input
