export type ErrorData = {
  message: string
  code?: string
}

export type ErrorProps = {
  code?: string
} & (
  | { message: string; errors?: never }
  | { message?: never; errors: ErrorData[] }
)

export class MotifError extends Error {
  code?: string
  errors: ErrorData[]

  constructor({ message, code, errors }: ErrorProps) {
    const error: ErrorData = message
      ? { message, ...(code ? { code } : {}) }
      : (errors?.[0] as ErrorData)

    super(error.message)
    this.errors = message ? [error] : (errors as ErrorData[])

    if (error.code) this.code = error.code
  }
}

// Used for errors that come from a bad implementation of the hooks
export class ValidationError extends MotifError {
  constructor(options: ErrorProps) {
    super(options)
    this.code = 'validation_error'
  }
}

export class FetcherError extends MotifError {
  status: number

  constructor(
    options: {
      status: number
    } & ErrorProps
  ) {
    super(options)
    this.status = options.status
  }
}

type Fetcher<T = any, B = any> = (options: FetcherOptions<B>) => T | Promise<T>

export type FetcherOptions<Body = any> = {
  url?: string
  query?: string
  method?: string
  variables?: any
  body?: Body
  headers?: any
}

const motifFetch: Fetcher = async ({
  url = '',
  method = 'GET',
  variables,
  body: bodyObj,
  headers: headersObj,
}) => {
  const hasBody = Boolean(variables || bodyObj)
  const body = hasBody
    ? JSON.stringify(variables ? { variables } : bodyObj)
    : undefined
  const headers = {
    ...(hasBody ? { 'Content-Type': 'application/json' } : {}),
    ...headersObj,
  }
  const res = await fetch(url, { method, body, headers })
  if (res.ok) {
    return res.json()
  }
}

export const motifFetchText: Fetcher = async ({
  url = '',
  method = 'GET',
  variables,
  body: bodyObj,
  headers: headersObj,
}) => {
  const hasBody = Boolean(variables || bodyObj)
  const body = hasBody
    ? JSON.stringify(variables ? { variables } : bodyObj)
    : undefined
  const headers = {
    ...(hasBody ? { 'Content-Type': 'application/json' } : {}),
    ...headersObj,
  }
  const res = await fetch(url, { method, body, headers })
  if (res.ok) {
    return res.text()
  }
}

export default motifFetch
