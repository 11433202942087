import cn from 'classnames'
import type { ButtonHTMLAttributes, JSXElementConstructor } from 'react'
import { forwardRef, useRef } from 'react'
import mergeRefs from 'react-merge-refs'

import LoadingDots from '@components/ui/LoadingDots'

import s from './Button.module.css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?: 'plain' | 'secondary' | 'text' | 'link'
  intent?: 'default' | 'danger'
  size?: 'default' | 'small' | 'large'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: JSXElementConstructor<any> | string
  width?: string | number
  loading?: boolean
  disabled?: boolean
  target?: '_blank'
  rel?: 'noreferrer'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: any, buttonRef) => {
    const {
      className,
      variant = 'plain',
      intent = 'default',
      size = 'default',
      children,
      loading = false,
      disabled = false,
      Component = 'button',
      ...restProps
    } = props
    const ref = useRef<typeof Component>(null)

    return (
      <Component
        ref={mergeRefs([ref, buttonRef])}
        className={cn(className, s.root, {
          [s.sizeDefault]: size === 'default',
          [s.sizeSmall]: size === 'small',
          [s.sizeLarge]: size === 'large',
          [s.plainDefault]: variant === 'plain' && intent === 'default',
          [s.plainDanger]: variant === 'plain' && intent === 'danger',
          [s.secondaryDefault]: variant === 'secondary' && intent === 'default',
          [s.secondaryDanger]: variant === 'secondary' && intent === 'danger',
          [s.textDefault]: variant === 'text' && intent === 'default',
          [s.textDanger]: variant === 'text' && intent === 'danger',
          [s.linkDefault]: variant === 'link' && intent === 'default',
          [s.linkDanger]: variant === 'link' && intent === 'danger',
        })}
        disabled={disabled || loading}
        {...restProps}
      >
        <div
          className={cn('self-center', {
            'opacity-0': loading,
            'opacity-100': !loading,
          })}
        >
          {children}
        </div>
        {loading && (
          <i className="absolute left-0 right-0 top-0 bottom-0 flex justify-center">
            <LoadingDots
              className={cn({
                'bg-neutral-500': variant === 'plain' && intent === 'default',
                'bg-red-500': variant !== 'plain' && intent === 'danger',
                'bg-neutral-400':
                  variant === 'secondary' && intent === 'default',
              })}
            />
          </i>
        )}
      </Component>
    )
  }
)

Button.displayName = 'Button'

export default Button
