export const isProd = (): boolean => {
  return !!(process && process.env.NODE_ENV === 'production')
}

export const isPreview = (): boolean => {
  return isProd() && process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview'
}

export const isDev = (): boolean => {
  return !isProd()
}
