import { NextSeo } from 'next-seo'
import type { FC } from 'react'

import LoginView from '@components/auth/LoginView'
import Motif from '@components/icons/Motif'

const Login: FC = () => {
  return (
    <div>
      <NextSeo title="Sign in" />
      <div className="app flex h-full w-full flex-col items-center justify-center p-8 pt-32">
        <Motif width="48" height="48" />
        <h1 className="mb-8 mt-10 text-lg font-medium">Sign in to Motif</h1>
        <div style={{ maxWidth: 320 }} className="w-full">
          <LoginView
            onSuccess={() => {
              window.location.replace('/')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
