import { validate } from 'email-validator'
import { Magic } from 'magic-sdk'
import type { FC, SyntheticEvent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import Button from '@components/ui/Button'
import Input from '@components/ui/Input'
import { useStore } from '@context/store'
import { timeout } from '@lib/utils/utils.edge'
import { MOTIF_DISCORD_INVITE_URL } from '@motif/shared/constants'
import motifFetch, { FetcherError } from '@motif/shared/fetcher'

interface Props {
  inviteCode?: string
  onLoggingIn?: (isLoggingIn: boolean) => void
  onSuccess?: () => void
}

const LoginView: FC<Props> = ({ inviteCode, onLoggingIn, onSuccess }) => {
  const { setCurrentUserId } = useStore()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const inputRef = useRef<HTMLInputElement>()

  const handleLogin = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    if (!dirty && !disabled) {
      setDirty(true)
      handleValidation()
    }

    try {
      setLoading(true)
      setMessage('')
      onLoggingIn?.(true)

      const did = await new Magic(
        process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY || '',
        { testMode: email === process.env.NEXT_PUBLIC_MAGIC_TEST_EMAIL }
      ).auth.loginWithMagicLink({ email })

      // If no inviteCode is provided, only returning users can log in.
      const response = await motifFetch({
        url: '/api/user/login',
        method: 'POST',
        body: JSON.stringify({ email, inviteCode }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${did}`,
        },
      })

      if (response.userId) {
        setCurrentUserId(response.userId)
      }

      if (response.didCreateNewUser) {
        await timeout(100)
      }

      onSuccess?.()
      await timeout(1000)
      setLoading(false)
      onLoggingIn?.(false)
    } catch (e) {
      setLoading(false)
      onLoggingIn?.(false)
      if (e instanceof FetcherError) {
        if (e.code === 'invite_code_required') {
          setMessage(
            <>
              Invite code required. Please request one on{' '}
              <a
                href={MOTIF_DISCORD_INVITE_URL}
                target="_blank"
                rel="noreferrer"
                className="text-medium underline"
              >
                Discord
              </a>
              .
            </>
          )
        }
      } else if (e instanceof Error && e?.message) {
        setMessage(e.message)
      } else if (e) {
        setMessage(`${e}`)
      }
    }
  }

  const handleValidation = useCallback(() => {
    // Unable to send form unless fields are valid.
    if (dirty) {
      setDisabled(!validate(email))
    }
  }, [email, dirty])

  useEffect(() => {
    handleValidation()
  }, [handleValidation])

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <form
      onSubmit={handleLogin}
      className="flex w-full flex-col justify-between p-3"
    >
      <div className="flex justify-center"></div>
      <div className="flex flex-col space-y-3">
        {message && <div className="px-2 text-sm text-rose-500">{message}</div>}
        <label htmlFor="email">
          <span className="sr-only mb-1 ml-1 block text-sm">Email</span>
          <Input
            ref={inputRef}
            autoFocus
            type="email"
            autoComplete="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={setEmail}
          />
        </label>
        <Button type="submit" loading={loading} disabled={disabled}>
          Continue
        </Button>
      </div>
    </form>
  )
}

export default LoginView
